import React from 'react';
import { AccountContextProvider } from './src/context/account/account.context';
import OrderContextProvider from './src/context/order/order.context';
import { LoadingAnimationContextProvider } from './src/context/loading-animation/loading-animation.context';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({
    config: { // Application Insights Configuration
        instrumentationKey: process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY,
        connectionString: process.env.GATSBY_APP_INSIGHTS_CONNECTIION_STRING,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
        disableTelemetry: true
        /* ...Other Configuration Options... */
    }
}); 

export const onClientEntry = () => {

    if (process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY && process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY !== "") {
        appInsights.loadAppInsights();
        appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    }
    
    const SESSION_STORAGE_REFERRER = 'referrer';
    if (document.referrer && !sessionStorage.getItem(SESSION_STORAGE_REFERRER)) {
        sessionStorage.setItem(SESSION_STORAGE_REFERRER, document.referrer);
    }
};

export const wrapRootElement = ({ element }) => (
    <LoadingAnimationContextProvider>
        <OrderContextProvider>
            <AccountContextProvider>{element}</AccountContextProvider>
        </OrderContextProvider>
    </LoadingAnimationContextProvider>
);
