import { Configuration, PublicClientApplication, LogLevel } from '@azure/msal-browser';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: `${process.env.GATSBY_HS_MSAL_POLICY_SIGNUPSIGNIN}`,
        passwordChange: `${process.env.GATSBY_HS_MSAL_POLICY_PASSWORD_CHANGE}`,
        passwordReset: `${process.env.GATSBY_HS_MSAL_POLICY_PASSWORD_RESET}`,
        signUp: `${process.env.GATSBY_HS_MSAL_POLICY_SIGNUP}`,
    },
    authorities: {
        signUpSignIn: {
            authority: `${process.env.GATSBY_HS_MSAL_AUTHORITY_SIGNUPSIGNIN}`,
        },
        passwordChange: {
            authority: `${process.env.GATSBY_HS_MSAL_AUTHORITY_PASSWORD_CHANGE}`,
        },
        passwordReset: {
            authority: `${process.env.GATSBY_HS_MSAL_AUTHORITY_PASSWORD_RESET}`,
        },
        signUp: {
            authority: `${process.env.GATSBY_HS_MSAL_AUTHORITY_SIGNUP}`,
        },
    },
    authorityDomain: `${process.env.GATSBY_HS_MSAL_AUTHORITY_DOMAIN}`,
};

export const msalConfig: Configuration = {
    auth: {
        clientId: `${process.env.GATSBY_HS_MSAL_CLIENT_ID}`,
        authority: `${process.env.GATSBY_HS_MSAL_AUTHORITY_SIGNUPSIGNIN}`,
        knownAuthorities: [`${process.env.GATSBY_HS_MSAL_AUTHORITY_DOMAIN}`],
        redirectUri: typeof window !== 'undefined' ? window.self.location.origin : `${process.env.GATSBY_SITE_URL}`,
        postLogoutRedirectUri: typeof window !== 'undefined' ? window.self.location.origin : `${process.env.GATSBY_SITE_URL}`,
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
        // allowRedirectInIframe: true,
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        // console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.log(message);
                        return;
                    case LogLevel.Warning:
                        // console.warn(message);
                        return;
                }
            },
        },
    },
};

export const msalApp = PublicClientApplication
    ? new PublicClientApplication({
          ...msalConfig,
          auth: { ...msalConfig.auth, authority: `${process.env.GATSBY_HS_MSAL_AUTHORITY_SIGNUPSIGNIN}` },
      })
    : null;
