import React from 'react';
import LoadingOverlay from '../../components/_common/loading-overlay/loading-overlay';

type ILoadingAnimationContext = {
    isLoading: boolean;
    showLoadingAnimation: () => void;
    hideLoadingAnimation: () => void;
};

const defaultContext: ILoadingAnimationContext = {
    isLoading: false,
    showLoadingAnimation: () => {},
    hideLoadingAnimation: () => {},
};

const LoadingAnimationContext = React.createContext<ILoadingAnimationContext>(defaultContext);

type LoadingAnimationContextProviderProps = {
    children: React.ReactNode;
};

const LoadingAnimationContextProvider = (props: LoadingAnimationContextProviderProps) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const showLoadingAnimation = () => {
        setIsLoading(true);
    };

    const hideLoadingAnimation = () => {
        setIsLoading(false);
    };

    return (
        <LoadingAnimationContext.Provider
            value={{
                isLoading,
                showLoadingAnimation,
                hideLoadingAnimation,
            }}
        >
            {props.children}
            <LoadingOverlay active={isLoading} />
        </LoadingAnimationContext.Provider>
    );
};

export { LoadingAnimationContextProvider };
export default LoadingAnimationContext;
