import React from 'react';
import * as ModalStyles from './modal.module.scss';

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    content?: React.ReactNode;
    showCloseButton?: boolean; // Neues Prop
}

const Modal: React.FC<ModalProps> = ({ isOpen, closeModal, content, showCloseButton = true }) => {
    // Default Wert auf true gesetzt
    if (!isOpen) return null;

    return (
        <div className={ModalStyles.modalOverlay}>
            <div className={ModalStyles.modal}>
                {showCloseButton && ( // Conditional Rendering
                    <button className={ModalStyles.modalCloseBtn} onClick={closeModal}>
                        Schließen
                    </button>
                )}
                <div className={ModalStyles.modalContent}>{content}</div>
            </div>
        </div>
    );
};

export default Modal;
