import React, { ReactNode, useState, useContext } from 'react';
import Modal from '../../components/_common/modal/modal';

interface IModal {
    isOpen: boolean;
    content?: ReactNode;
    openModal: (content: ReactNode, showCloseButton: boolean) => void;
    closeModal: () => void;
}

const defaultContext: IModal = {
    isOpen: false,
    openModal: () => {},
    closeModal: () => {},
};

const ModalContext = React.createContext<IModal>(defaultContext);

type ModalContextProviderProps = {
    children: ReactNode;
};

const ModalContextProvider: React.FC<ModalContextProviderProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [content, setContent] = useState<ReactNode | undefined>();
    const [showCloseButton, setShowCloseButton] = useState<boolean>(true);

    const openModal = (newContent: ReactNode, newShowCloseButton: boolean) => {
        setIsOpen(true);
        setContent(newContent);
        setShowCloseButton(newShowCloseButton);

        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsOpen(false);
        setContent(undefined);
        document.body.style.overflow = 'unset';
    };

    return (
        <ModalContext.Provider value={{ isOpen, content, openModal, closeModal }}>
            {children}
            <Modal isOpen={isOpen} closeModal={closeModal} content={content} showCloseButton={showCloseButton} />
        </ModalContext.Provider>
    );
};

// Hook für einfachen Zugriff
const useModal = () => {
    return useContext(ModalContext);
};

export { ModalContextProvider, useModal };
export default ModalContext;
