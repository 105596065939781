import { cloneDeep, isArray } from 'lodash';

import { PaymentType } from '../../_shared/enums/payment-type';
import { IAddress } from '../../_shared/interfaces/address';
import { IArticle } from '../../_shared/interfaces/article';
import { IOrder } from '../../_shared/interfaces/order';
import { IOrderCustomer } from '../../_shared/interfaces/order-customer';
import { StepState, StepStates } from '../../context/order/order.context';
import { StoryblokCommon } from '../storyblok/storyblok';

let uniqueId = 0;

export const defaultOrderCustomer: IOrderCustomer = {
    eMail: '',
    accountId: undefined,
    firstName: '',
    lastName: '',
    taxNumber: '',
    language: undefined,
    country: undefined,
};

export const defaultAddress: IAddress = Object.freeze({
    company: '',
    supplement: '',
    street: '',
    postalCode: '',
    city: '',
    country: '',
});

export const isAddressValid = (address?: IAddress): boolean => {
    return !!(address && address.company && address.city && address.street && address.country && address.postalCode);
};

export const isEmailValid = (email: string): boolean => {
    // prettier-ignore
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
    // prettier-ignore
    const re =
        /^\+?\(?(?:[0-9](-|\s|\(|\)|\.|\s\(|\)\s|-\(|\)-|\.\s)?){6,14}[0-9]\)?$/;
    return re.test(String(phoneNumber));
};

export const isGlobalDataSource = (storyblokCommon: StoryblokCommon): boolean => {
    return storyblokCommon.configuration.content.use_global_datasources === true;
};

export const isArticleValid = (article?: IArticle): boolean => {
    return !(!article || !article.product || !article.service);
};

export const newUniqueId = (): string => {
    uniqueId++;
    return `uid-${uniqueId}`;
};

export const deliveryErrorCheck = (order: IOrder): { deliveryFromError: boolean; deliveryToError: boolean } => {
    const result = { deliveryFromError: true, deliveryToError: true };

    if (
        order.deliveryFromCustomer &&
        order.deliveryFromCustomer.type &&
        (order.deliveryFromCustomer.type === 'self-organized' ||
            (order.deliveryFromCustomer.type === 'pickup' &&
                order.deliveryFromCustomer.pickupDetails?.date &&
                isAddressValid(order.deliveryFromCustomer.pickupDetails?.address) &&
                order.deliveryFromCustomer.pickupDetails?.phone))
    ) {
        result.deliveryFromError = false;
    }

    if (order.deliveryToCustomer && isAddressValid(order.deliveryToCustomer.address)) {
        result.deliveryToError = false;
    }

    return result;
};

export const paymentErrorCheck = (order: IOrder): boolean => {
    return (
        order.payment == null || order.payment.type === PaymentType.None || order.invoice == null || !isAddressValid(order.invoice?.address)
    );
};

export const overviewErrorCheck = (order: IOrder, stepStates: StepStates, taxfieldRequired: boolean): boolean => {
    return (
        stepStates.payment !== StepState.Done ||
        stepStates.delivery !== StepState.Done ||
        stepStates.service !== StepState.Done ||
        !order.customer ||
        !order.customer.eMail ||
        !isEmailValid(order.customer.eMail) ||
        !order.customer.firstName ||
        !order.customer.lastName ||
        (taxfieldRequired && !order.customer.taxNumber)
    );
};

export const scrollToFirstError = (formStylesError: any): void => {
    if (formStylesError && typeof window !== 'undefined') {
        setTimeout(() => {
            const elem = document.querySelector('.' + formStylesError)?.parentElement;
            if (elem) {
                try {
                    const formElement = elem.querySelectorAll('input, select, textarea')[0];
                    const position = formElement ? formElement.getBoundingClientRect() : elem.getBoundingClientRect();
                    window.scrollTo({
                        top: position.top + window.scrollY - 180,
                        left: 0,
                        behavior: 'smooth',
                    });
                } catch (e) {}
            }
        }, 100);
    }
};

export const getFloatFromString = (value: string): number => {
    const result = parseFloat(value);
    if (isFinite(result)) {
        return result;
    }
    return 0.0;
};

export const getIntFromString = (value: string): number => {
    const result = parseInt(value, 10);
    if (isFinite(result)) {
        return result;
    }
    return 0;
};

export const getDatasources = (
    storyblokCommon: StoryblokCommon,
    type: 'category' | 'manufacturer' | 'manufacturers_global' | 'categories_global',
    country: string | undefined,
): { name: string; value: string }[] => {
    const filteredDataSources: { name: string; value: string }[] = [];

    if (!country) {
        return filteredDataSources;
    }

    let dataSourceName: string = '';
    if (type === 'category') {
        dataSourceName = `category-${country.toLowerCase()}`;
        if (country.toLowerCase() === 'de') {
            dataSourceName = 'category';
        }
    }

    if (type === 'categories_global' && isGlobalDataSource(storyblokCommon)) {
        dataSourceName = `ds-categories-global`;
    }

    if (type === 'manufacturer') {
        dataSourceName = `manufacturer-${country.toLowerCase()}`;
        if (country.toLowerCase() === 'de') {
            dataSourceName = 'manufacturer';
        }
    }

    if (type === 'manufacturers_global' && isGlobalDataSource(storyblokCommon)) {
        dataSourceName = `ds-manufacturers-global`;
    }

    if (dataSourceName !== '') {
        storyblokCommon.datasources.forEach((d) => {
            if (d.node.data_source === dataSourceName) {
                filteredDataSources.push({ name: d.node.name, value: d.node.value });
            }
        });
    }

    return filteredDataSources;
};

export const replaceInRichText = (richText: any, findString: string, replaceString: string): any => {
    const richTextDeepCopy = cloneDeep(richText);
    if (isRichText(richTextDeepCopy)) {
        richTextDeepCopy.content = richTextDeepCopy.content.map((mainContent: any) => {
            if (Array.isArray(mainContent.content)) {
                mainContent.content = mainContent.content.map((subContent: any) => {
                    if (typeof subContent.text === 'string') {
                        subContent.text = subContent.text.replace(new RegExp(findString, 'g'), replaceString);
                    }
                    return subContent;
                });
            }
            return mainContent;
        });
    }
    return richTextDeepCopy;
};

export const replaceText = (text: string, findString: string, replaceString: string): string => {
    return text.replace(new RegExp(findString, 'g'), replaceString);
};

export const isRichText = (richText: any): boolean => {
    return (
        typeof richText === 'object' &&
        richText.type === 'doc' &&
        isArray(richText.content) &&
        richText.content.length &&
        isArray(richText.content[0].content) &&
        richText.content[0].content.length
    );
};
