import axios from 'axios';
import { useEffect, useState } from 'react';
import StoryblokClient from 'storyblok-js-client';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';

import {
    ConfigurationStoryblok,
    CookieNotificationStoryblok,
    FooterStoryblok,
    ManufacturersStoryblok,
    NotFoundStoryblok,
    PageAccountStoryblok,
    TopNavigationStoryblok,
} from '../../_shared/interfaces/storyblok';

declare global {
    interface Window {
        StoryblokBridge: any;
    }
}

export const SB_PLACEHOLDER_SIGN_IN = 'azure-ad-b2c-login';
export const SB_PLACEHOLDER_SIGN_UP = 'azure-ad-b2c-signup';

export interface StoryblokCommon {
    configuration: { content: ConfigurationStoryblok; uuid: string };
    accountDetailPage?: { content: PageAccountStoryblok; uuid: string };
    topNavigation: { content: TopNavigationStoryblok; uuid: string };
    footer: { content: FooterStoryblok; uuid: string };
    cookieNotification: { content: CookieNotificationStoryblok; uuid: string };
    notFound: { content: NotFoundStoryblok; uuid: string };
    datasources: { node: { name: string; data_source: string; value: string } }[];
    workshopServices: { content: ManufacturersStoryblok; uuid: string };
    onSiteServices: { content: ManufacturersStoryblok; uuid: string } | undefined;
}

interface StoryblokData {
    story: { content: any } | undefined;
}

let Storyblok: any;

const TOKEN_REQUEST_FAILED_ERROR_MESSAGE =
    'Error: Storyblok token request failed - draft view will not work for this session. You will see published content.';

export const useStoryBlockCommon = (storyblokPath: string): StoryblokCommon => {
    const {
        StoryblokConfigurations,
        StoryblokAccountDetailPages,
        StoryblokTopNavigations,
        StoryblokFooters,
        StoryblokCookieNotifications,
        StoryblokNotFound,
        StoryblokWorkshopServices,
        StoryblokOnSiteServices,
        StoryblokDatasources,
    } = useStaticQuery(
        graphql`
            query {
                StoryblokConfigurations: allStoryblokEntry(filter: { field_component: { eq: "configuration" } }) {
                    edges {
                        node {
                            content
                            full_slug
                            uuid
                        }
                    }
                }
                StoryblokAccountDetailPages: allStoryblokEntry(filter: { field_component: { eq: "page_account" } }) {
                    edges {
                        node {
                            content
                            full_slug
                            uuid
                        }
                    }
                }
                StoryblokTopNavigations: allStoryblokEntry(filter: { field_component: { eq: "top_navigation" } }) {
                    edges {
                        node {
                            content
                            uuid
                            full_slug
                            children {
                                id
                                children {
                                    id
                                }
                            }
                        }
                    }
                }
                StoryblokFooters: allStoryblokEntry(filter: { field_component: { eq: "footer" } }) {
                    edges {
                        node {
                            content
                            uuid
                            full_slug
                        }
                    }
                }
                StoryblokCookieNotifications: allStoryblokEntry(filter: { field_component: { eq: "cookie_notification" } }) {
                    edges {
                        node {
                            content
                            uuid
                            full_slug
                        }
                    }
                }
                StoryblokNotFound: allStoryblokEntry(filter: { field_component: { eq: "not_found" } }) {
                    edges {
                        node {
                            content
                            uuid
                            full_slug
                        }
                    }
                }
                StoryblokWorkshopServices: allStoryblokEntry(
                    filter: { slug: { eq: "workshop_services" }, field_component: { eq: "manufacturers" } }
                ) {
                    edges {
                        node {
                            content
                            uuid
                            full_slug
                        }
                    }
                }
                StoryblokOnSiteServices: allStoryblokEntry(
                    filter: { slug: { eq: "on_site_services" }, field_component: { eq: "manufacturers" } }
                ) {
                    edges {
                        node {
                            content
                            uuid
                            full_slug
                        }
                    }
                }
                StoryblokDatasources: allStoryblokDatasourceEntry {
                    edges {
                        node {
                            name
                            data_source
                            value
                        }
                    }
                }
            }
        `,
    );

    const [storyBlockCommon, setStoryBlockCommon] = useState<StoryblokCommon>({
        configuration: getStoryblokContent(StoryblokConfigurations, storyblokPath, 'configuration'),
        accountDetailPage: getStoryblokContent(StoryblokAccountDetailPages, storyblokPath, 'account detail pages'),
        topNavigation: getStoryblokContent(StoryblokTopNavigations, storyblokPath, 'top navigation'),
        footer: getStoryblokContent(StoryblokFooters, storyblokPath, 'footer'),
        cookieNotification: getStoryblokContent(StoryblokCookieNotifications, storyblokPath, 'cookie notification'),
        notFound: getStoryblokContent(StoryblokNotFound, storyblokPath, 'not found'),
        datasources: StoryblokDatasources.edges,
        workshopServices: getStoryblokContent(StoryblokWorkshopServices, storyblokPath, 'workshop services'),
        onSiteServices: getStoryblokContentOptional(StoryblokOnSiteServices, storyblokPath),
    });

    useEffect(() => {
        if (isStoryblokEditor()) {
            const storyBlockCommonDraft = _.cloneDeep(storyBlockCommon);

            // TODO: datasources updates actually will not work until redeployment

            const getStoryBlockCommonFromDraft = async (): Promise<StoryblokCommon> => {
                const configurationData = await getContentFromDraft(storyBlockCommon.configuration.uuid, true);
                const topNavigationData = await getContentFromDraft(storyBlockCommon.topNavigation.uuid, true);
                const footerData = await getContentFromDraft(storyBlockCommon.footer.uuid, true);
                const cookieNotificationData = await getContentFromDraft(storyBlockCommon.cookieNotification.uuid, true);
                const notFoundData = await getContentFromDraft(storyBlockCommon.notFound.uuid, true);
                const workshopServicesData = await getContentFromDraft(storyBlockCommon.workshopServices.uuid, true);
                const onSiteServicesData = storyBlockCommon.onSiteServices
                    ? await getContentFromDraft(storyBlockCommon.onSiteServices.uuid, true)
                    : undefined;

                storyBlockCommonDraft.configuration.content = configurationData.story?.content;
                storyBlockCommonDraft.topNavigation.content = topNavigationData.story?.content;
                storyBlockCommonDraft.footer.content = footerData.story?.content;
                storyBlockCommonDraft.cookieNotification.content = cookieNotificationData.story?.content;
                storyBlockCommonDraft.notFound.content = notFoundData.story?.content;
                storyBlockCommonDraft.workshopServices.content = workshopServicesData.story?.content;
                if (storyBlockCommonDraft.onSiteServices && onSiteServicesData) {
                    storyBlockCommonDraft.onSiteServices.content = onSiteServicesData.story?.content;
                }

                return storyBlockCommonDraft;
            };

            getStoryBlockCommonFromDraft().then((draft) => {
                setStoryBlockCommon(draft);
            });
        }
    }, []);

    return storyBlockCommon;
};

export const useStoryblok = (originalStory: any) => {
    const [story, setStory] = useState(originalStory);

    if (story && typeof story.content === 'string') {
        story.content = JSON.parse(story.content);
    }

    const initEventListeners = () => {
        const { StoryblokBridge } = window;

        if (typeof StoryblokBridge !== 'undefined') {
            const storyblokInstance = new StoryblokBridge();

            storyblokInstance.on(['input'], (event: StoryblokEventPayload) => {
                if (story && event.story) {
                    if (event.story._uid === story._uid) {
                        setStory(event.story);
                    }
                } else {
                    setStory(event.story);
                }
            });
        }
    };

    const addBridge = (callback: any) => {
        const existingScript = document.getElementById('storyblokBridge');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`;
            script.id = 'storyblokBridge';
            document.body.appendChild(script);
            script.onload = () => {
                callback();
            };
        } else {
            callback();
        }
    };

    const loadDraft = (storyId?: string | null) => {
        // check for URL params if storyId is missing
        if (!storyId && typeof window !== 'undefined' && window.location.search) {
            const urlParams = new URLSearchParams(window.location.search);
            storyId = urlParams.get('_storyblok');
        }

        getContentFromDraft(storyId ? storyId : location.pathname, false).then((data) => {
            if (data.story) {
                setStory(data.story);
            }
        });
    };

    useEffect(() => {
        if (isStoryblokEditor()) {
            addBridge(initEventListeners);
            if (originalStory && originalStory.internalId) {
                loadDraft(originalStory.internalId.toString());
            } else {
                loadDraft();
            }
        }
    }, []);

    return story;
};

export const getRichTextMarkup = (richtext: any): any => {
    const storyblokClient = new StoryblokClient({});
    return {
        __html: storyblokClient.richTextResolver.render(richtext),
    };
};

const getContentFromDraft = async (uuid: string, findById: boolean = true): Promise<StoryblokData> => {
    await getStoryblokToken().then((token) => {
        return token;
    });

    await initStoryblok();

    return await Storyblok.get(`cdn/stories/${uuid}`, {
        version: 'draft',
        find_by: findById ? 'uuid' : undefined,
        cache: false,
    })
        .then(({ data }: { data: StoryblokData }) => {
            return data;
        })
        .catch((error: any) => {
            // tslint:disable-next-line:no-console
            console.error(error);
        });
};

export const isStoryblokEditor = (): boolean => {
    if (typeof window !== 'undefined') {
        let urlParams: string | null;
        if (window.location.search) {
            urlParams = window.location.search;
            // Remember the ULR parameters if the user navigates within the website
            sessionStorage.setItem('location-search', window.location.search);
        } else {
            urlParams = sessionStorage.getItem('location-search');
        }
        return !!(urlParams && urlParams.includes('_storyblok_tk[token]'));
    } else {
        return false;
    }
};

const getStoryblokContent = (storyBlokQueryResult: any, storyblokPath: string, name: string): { content: any; uuid: string } => {
    if (!storyBlokQueryResult.edges.length) {
        throw new Error(`No ${name} found`);
    } else {
        const languageNode = getLanguageNode(storyBlokQueryResult.edges, storyblokPath);
        if (languageNode) {
            return { content: JSON.parse(languageNode.node.content), uuid: languageNode.node.uuid };
        } else {
            throw new Error(`No language specific ${name} found`);
        }
    }
};

const getStoryblokContentOptional = (storyBlokQueryResult: any, storyblokPath: string): { content: any; uuid: string } | undefined => {
    if (!storyBlokQueryResult.edges.length) {
        return undefined;
    } else {
        const languageNode = getLanguageNode(storyBlokQueryResult.edges, storyblokPath);
        if (languageNode) {
            return { content: JSON.parse(languageNode.node.content), uuid: languageNode.node.uuid };
        } else {
            return undefined;
        }
    }
};

const getLanguageNode = (entries: any[], storyblokPath: string): any | undefined => {
    // TODO: Mehrsprachigkeit wird noch nicht richtig funktionieren:
    //  Zuerst müsste ich den ersten beiden ordner suchen, also zb "ch/de" und erst dann in "ch"

    const pathFragments = storyblokPath.split('/');
    let result = entries.find((entry) => {
        const fullSlugFragments = entry.node.full_slug.split('/');
        if (pathFragments[0] === fullSlugFragments[0]) {
            return true;
        }
    });

    if (!result) {
        result = entries.find((entry) => {
            const fullSlugFragments = entry.node.full_slug.split('/');
            if (process.env.GATSBY_COUNTRY === fullSlugFragments[0]) {
                return true;
            }
        });
    }

    return result;
};

const getStoryblokToken = (): Promise<string> => {
    return new Promise((resolve) => {
        const token = sessionStorage.getItem('storyblok-token') ?? '';
        const error = sessionStorage.getItem('storyblok-token-request error') ?? '';

        if (token) {
            resolve(token);
        } else if (typeof window !== 'undefined' && !error) {
            const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_STORYBLOK_TOKEN}`.replace(
                '%%STORYBLOK-PARAMS%%',
                window.location.search,
            );
            axios
                .get(url, {})
                .then((response) => {
                    sessionStorage.setItem('storyblok-token', response.data);
                    resolve(response.data);
                })
                .catch(() => {
                    sessionStorage.setItem('storyblok-token-request error', 'true');
                    alert(TOKEN_REQUEST_FAILED_ERROR_MESSAGE);
                });
        } else if (error) {
            alert(TOKEN_REQUEST_FAILED_ERROR_MESSAGE);
        }
    });
};

const initStoryblok = async () => {
    if (!Storyblok) {
        const token = await getStoryblokToken().then((t) => {
            return t;
        });

        if (!Storyblok) {
            Storyblok = new StoryblokClient({
                accessToken: token,
                cache: {
                    clear: 'auto',
                    type: 'memory',
                },
            });
        }
    }
};
