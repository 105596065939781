import React from 'react';

import * as LoadingOverlayStyles from './loading-overlay.module.scss';

const LoadingOverlay = ({ active }: { active: boolean }) => {
    return (
        <div id="loading-overlay" className={LoadingOverlayStyles.loadingOverlay} style={{ display: active ? 'block' : 'none' }}>
            <div className={LoadingOverlayStyles.inner}>
                <div className={LoadingOverlayStyles.content}>
                    <span className={LoadingOverlayStyles.spinner} />
                </div>
            </div>
        </div>
    );
};

export default LoadingOverlay;
