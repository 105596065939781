import { msalApp } from '../msal/msal';
declare global {
    interface Window {
        dte_statistic: any[];
    }
}

export const trackPage = (pageData: { country?: string; language?: string; title?: string; template?: string }) => {
    if (typeof window !== 'undefined') {
        window.dte_statistic = window.dte_statistic || [];

        window.dte_statistic.push({
            action: 'page.ready',
            data: {
                page: pageData,
                user: { loginStatus: msalApp && msalApp.getAllAccounts().length > 0 ? 'logged_in' : 'logged_out' },
            },
        });
    }
};

export const trackEvent = (action: string, data: any) => {
    if (typeof window !== 'undefined') {
        window.dte_statistic = window.dte_statistic || [];

        window.dte_statistic.push({
            action,
            data,
        });
    }
};
